body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1F2620
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  width: 100%;
  height: 100%;
}


.navbar{
  height:60px;
  width: 100%;
  background-color: #3e4f3c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fffbf6;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
}

.nav-left{
  display: flex;
  align-items: center;
}

.nav-right{
  display: none;
  align-items: center;

}

.nav-logo-div{
  width: 100px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-left: -10px;
}

.nav-logo{
  height: 40px;
}

.logo-txt{
  font-size: 10px;
  margin-top: 5px;
}



.nav-title{
  margin-left: 20px;
  display: flex;
  
}


.nav-user{
  width: 35px;
  height: 35px;
  background-color: #323f28;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fffbf6;
  cursor: pointer;
}


.nav-signout-btn{
  height: 35px;
  width: 100px;
  background-color: #4f604d;
  border:none;
  border-radius: 5px;
  margin-left: 20px;
  color: #fffbf6;
  cursor: pointer;
}


.home{
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.home::-webkit-scrollbar {
  display: none;
}

.home-content{
  display: flex;
  height: 100%;
  padding:0px;
  position:relative
}


.side-menu{
  height: 100vh;
  width: 30%;
  background-color:#f4eddb;
  box-sizing: border-box;
  padding: 30px 50px 100px 50px;
  overflow: scroll;
  font-size: 14px;
  position: relative;
}




.home-right-content{
  height: 100vh;
  width: 70%;
  box-sizing: border-box;
  padding: 50px 150px 0px 150px;
  position: relative;
  overflow-y: auto;
}

.home-right-content::-webkit-scrollbar {
  display: none;
}


.chat-display{
  height: 80%;
  overflow: scroll;
  padding-left: 50px;
}





.chat-input-div{
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
}



.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}
.file-input label {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1F2620;
  font-weight: bold;
  cursor: pointer;
  transition: transform .2s ease-out;
}

.file-input-div{
  position: absolute;
  bottom: 18px;
  left: 1%;
  display: none;
}


.txt-input{
  width: 100%;
  height: 50px;
  border-radius: 15px;
  border: 1px solid gray;
  padding-left: 40px;
  padding-right: 130px;
  padding-top: 15px;
  box-sizing: border-box;
}

.info-btn{
  position: absolute;
  bottom:25px;
  left: -8%;
  border: 1px solid #1F2620;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.info-icon{
  width: 12px;
  height: 12px;
}


.chat-file-select{
  position: absolute;
  top: 0;
  left: 2%;
}


.chat-response{
  padding-bottom: 30px;
  z-index: 999;
}




.chat-res-info{
  margin-top: 20px;
  padding-top: 5px;
  box-sizing: border-box;
  border-top: 1px solid gray;
  position: relative;
}


.chat-res-icons{
  display: flex;
  gap: 10px;
  color: gray;
  margin-top: 15px;
}

.dark-green{
  font-weight:900;
}

.light-green{
  color:#404d37 ;
  font-weight:400;
}



/* Hide the checkbox */
.menu-toggle-checkbox {
  display: none;
}

/* Style for the label */
.menu-toggle-label {
  position: absolute;
  top: 80px;
  left: 40px;
  z-index: 999; /* Ensure it's above other content */
  cursor: pointer;
}

/* Style for the menu icon */
.menu-icon {
  font-size: 24px;
  transition: transform 0.3s ease;
}



/* Adjust the side menu position and width based on checkbox state */
.menu-toggle-checkbox:checked ~ .home-content .side-menu {
  display: inline-block;
  width: 30%;
}

.menu-toggle-checkbox:not(:checked) ~ .home-content .side-menu {
  display: none;
  width: 30%;
}


/* Hide home-right-content based on checkbox state */
.menu-toggle-checkbox:checked ~ .home-content .home-right-content {
  display: inline-block;
  width: 70%;
}

.menu-toggle-checkbox:not(:checked) ~ .home-content .home-right-content {
  display: inline-block;
  width: 100%;
}

/* Hide the second icon when the checkbox is checked */
.menu-toggle-checkbox:checked + .menu-toggle-label .fa-bars {
  display: none;
}

.menu-toggle-checkbox:checked + .menu-toggle-label{
  left: 32%;
}

.menu-toggle-checkbox:not(:checked) + .menu-toggle-label .fa-times {
  display: none;
}



@media (min-width: 781px) {
  .generate-btn{
    display: block;
    position: absolute;
    right: 2%;
    height: 35px;
    border: none;
    border-radius: 10px;
    width: 100px;
    background-color: #d96506;
    color: white;
    cursor: pointer;
  }
  
  
  .generate-btn-s{
    display: none;
  }

  
}

@media (max-width: 780px) {
  .generate-btn-s{
    position: absolute;
    right: 2%;
    height: 35px;
    border: none;
    border-radius: 10px;
    width: 80px;
    background-color: #d96506;
    color: white;
    cursor: pointer;
  }

  .generate-btn{
    display: none;
  }

  .txt-input{
    padding-left: 15px !important;
  }
  
}






/* Add these styles to your existing CSS */

@media (max-width: 999px) {
    
  .menu-toggle-checkbox:checked ~ .home-content .side-menu {
    display: block;
    width: 100%;
  }

  .menu-toggle-checkbox:not(:checked) ~ .home-content .side-menu {
    display: none;
    width: 30%;
  }



  .menu-toggle-checkbox:checked ~ .home-content .home-right-content {
    display: none;
    width: 70%;
  }

  .menu-toggle-checkbox:not(:checked) ~ .home-content .home-right-content {
    display: block;
    width: 100%;
  }

  .menu-toggle-checkbox:checked + .menu-toggle-label{
    left: 95%;
  }


  .refresh-btn{
    position: absolute;
    top: -40px !important;
    color:#f4eddb;
    right: 20px;
    cursor: pointer;
    z-index: 999;
  }
  
}



@media (max-width: 699px) {
  

  .nav-title{
    display: none;
  }

  .home-right-content{
    height: 100vh;
    width: 70%;
    box-sizing: border-box;
    padding: 50px 20px 0px 20px;
    position: relative;
  }

  /* Style for the label */
  .menu-toggle-label {
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 999; /* Ensure it's above other content */
    cursor: pointer;
    color:#fffbf6
  }

  .menu-toggle-checkbox:checked + .menu-toggle-label{
    left: 15px;
  }

  .generate-btn{
    display: none;
  }


  .info-btn{
    position: absolute;
    top: -15px;
    left: 0;
    border: 1px solid #1F2620;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  
  
  .chat-file-select{
    position: absolute;
    top: -10%;
    left: 7%;
  }

  .txt-input{
    padding-left: 15px !important;
  }

  .chat-input-div {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: white;
  }

}



@media (max-width: 391px) {
  .generate-btn{
    display: none;
  }

  .generate-btn-s{
    position: absolute;
    bottom:12px;
    right: 2%;
    height: 35px;
    border: none;
    border-radius: 10px;
    width: 80px;
    background-color: #d96506;
    color: white;
    cursor: pointer;
  }

  .txt-input{
    padding-left: 15px !important;
  }

  .info-btn{
    position: absolute;
    top: 10px;
    left: 0;
    border: 1px solid #1F2620;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .chat-file-select{
    position: absolute;
    top: 10px;
    left: 10%;
  }

  .txt-input{
    width: 100%;
    height: 50px;
    border-radius: 15px;
    border: 1px solid gray;
    padding-left: 40px;
    padding-right: 90px;
  }
  
  .file-input-div{
    position: absolute;
    bottom: 14px;
    left: 1%;
  }

  .nav-signout-btn{
    height: 35px;
    width: 100px;
    background-color: #4f604d;
    border:none;
    border-radius: 5px;
    margin-left: 20px;
    color: #fffbf6;
    cursor: pointer;
    margin-right: -30px;
  }

  .chat-input-div{
    padding-top: 40px;
    box-sizing: border-box;
    min-height: 100px !important;
    z-index: 999;
  }
}


.chat-txt{
  margin-top: 30px;
  position: relative;
}


.user-icon{
  position: absolute;
  top: -5px;
  left: -50px;
  width: 35px;
  height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9a9a9a;
  border-radius: 50px;
}

.assistant-icon{
  position: absolute;
  top: 15px;
  left: -50px;
  width: 35px;
  height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3e4f3c;
  border-radius: 50px;
}

.response-icon{
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50px;
}



.loading-div{
  width: 50px;
  height: 50px;
  display:flex;
  justify-content: center;
  align-items: center;
}



textarea {
  min-height: 50px !important;
  resize: none;
  /* Set minimum height to 50px */
}

textarea::-webkit-scrollbar {
  display: none;
}


.error{
  color: red;
}


.refresh-btn{
  position: absolute;
  top:20px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}







/* For WebKit browsers */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent background */
}

::-webkit-scrollbar-thumb {
    background-color: rgb(191, 191, 191); /* Color of the scrollbar */
    border-radius: 3px; /* Rounded corners */
}

